/**
 * Simple Question Component
 * 
 * Shows very bare information about an individual question.
 * Used in the Question Overview Page, for grab and go games.
 */
import React from 'react'
import SvgCheck from '../svg/check'
import SvgArrow from '../svg/arrow'
import { getCurrentTeamData, getHuntStatus } from '../../utils/team-data'

class QuestionSimple extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      numGuesses: 0, // number of guesses
      answer: ``, // the current guess/answer
      isCorrect: false,
      prevAnswers: [], // record all submitted answers
      isDirty: false, // marks if the input has changed but not been submitted/validated
      points: 0,
      modalShow: false,
      modalStyle: `danger`,
      buttonText: `Next Question`,
      questionState: ``,
    }
  }

  componentDidMount() {
    this.updateFromData()
    this.interval = setInterval(() => this.updateFromData(), 15 * 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  updateFromData() {
    const { question } = this.props
    const curTeamData = getCurrentTeamData()
    if (
      curTeamData !== undefined &&
      curTeamData.answers !== undefined &&
      curTeamData.answers !== null &&
      curTeamData.answers[question.id] !== undefined
    ) {
      const prevAnswer = curTeamData.answers[question.id]
      this.setState(prevAnswer)
      let q_state = ``
      if (prevAnswer.isCorrect) {
        if (prevAnswer.points == question.answer_value) {
          q_state = `correct`
        } else {
          q_state = `partial`
        }
      } else {
        if (prevAnswer.numGuesses >= this.props.allowedGuesses) {
          q_state = `wrong`
        }
      }
      this.setState({ questionState: q_state })
    }
    let state = getHuntStatus()
    if (state == `ended`) {
      clearInterval(this.interval)
    }
  }

  // Add modal to question, change color depending on number of tries.

  render() {
    const { question, direction, allowedGuesses } = this.props
    const isCorrect = this.state.isCorrect
    const showEnd = isCorrect || this.state.numGuesses >= allowedGuesses

    let pointsDisplay = <p>{question.answer_value} pts</p>

    return (
      <div className={`card card-simple mb-3`}>
        <div className="card-header">
          <div className={`row align-items-center justify-content-between`}>
            {showEnd ? (
              <>
                <div className={`col-3 text-left`}>
                  <p className={`my-0`}>
                    <span className={`text-lowercase`}>
                      {this.state.points}/{question.answer_value} pts
                    </span>
                  </p>
                </div>
                <div className={`col-6 text-center directions px-0`}>
                  {direction}
                </div>
                <div className={`col-3 text-right`}>
                  <span className={`check-circle ${this.state.questionState}`}>
                    <span className={`d-flex align-items-center justify-content-center w-100 h-100`}>
                      <SvgCheck />
                    </span>
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className={`col-3`}>
                  <p className={`my-0`}>
                    <span className={`text-lowercase`}>{question.answer_value} pts</span>
                  </p>
                </div>
                <div className={`col-6 text-center directions`}>
                  <span
                    style={{
                      letterSpacing: `1px`,
                    }}>
                    {direction}
                  </span>
                </div>
                <div className={`col-3 text-right`}>
                  <SvgArrow />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}

QuestionSimple.defaultProps = {
  q_num: 1,
  total_q: 1,
  allowedGuesses: 2,
  question: {},
  direction: `clockwise`,
}

export default QuestionSimple
